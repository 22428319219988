@import url("../assets/fonts/switzer/css/switzer.css");

body {
  background: #f5f5f5;
}

/* Font overrides */
:root {
  font-family: "Switzer-Variable";
  --ion-font-family: "Switzer-Variable";
  --ion-background-color: #f5f5f5;
}

/* QR Code */
.qr-video-cover video {
  object-fit: cover;
}

.qr-video-cover section,
.qr-video-cover div {
  height: 100%;
}

/* Datepicker */
.custom-datetime {
  --padding: 0;
  margin: 8px 0 !important;
}

/* Tab Bar Overrides */
.ion-tab-bar-override {
  --background: #fff;
  --color: #8b8b8b;
  --color-selected: #181818; /* Change 'red' to whatever color you want for the active icon */
}

/* Icon Override */
.ion-icon-color-override ion-icon {
  color: #8b8b8b;
}

.ion-icon-event-override {
  font-size: 20px;
  --ionicon-stroke-width: 40px;
}

.ion-icon-scanner-override {
  font-size: 36px;
}

/* Back button overrides */
.ion-back-button-override {
  --color: #181818;
  --color-focused: #181818;
  --color-hover: #181818;
}

/* Header Icon Overrides */
.header-icon-override {
  font-size: 24px;
}

.header-default-button-override {
  font-size: 16px;
  color: #181818;
  --color: #181818;
  --color-focused: #181818;
  --color-hover: #181818;
}

.header-icon-button-override {
  --background: transparent;
  --background-hover: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
  --color: #181818;
  --padding-start: 8px !important;
  --padding-end: 8px !important;
  --padding-top: 8px !important;
  --padding-bottom: 8px !important;
  margin-right: -8px;
}

/* Header Searchbar Override */
.header-searchbar-override {
  --border-radius: 0px;
  --background: #fff;
}

/* Ion padding overrides */
.ion-padding-page {
  --padding-start: 16px !important;
  --padding-end: 16px !important;
  --padding-top: 4px !important;
  --padding-bottom: 16px !important;
}

/* Toast Styles */
.success-toast {
  --background: #316d49;
  --color: #fff;
}

/* List Gradient */
.list-gradient {
  background: linear-gradient(0deg, #fff 0%, #f5f5f5 100%);
}

/* Autocomplete input overrides? */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  color: black !important;
}

/* Input overrides */
/* This is so that autocomplete doesn't overlap the label */
.ion-input-override input {
  margin-top: 2px !important;
}

/* Ion Item on Sessions Page*/
.ion-item-sessions-override {
  --background: transparent;
  --border-width: 0;
  --border-color: transparent;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
}

.ion-item-accordion-override {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
}

/* Sometimes dark mode was showing on iOS browsers even though we are forcing light for now */
ion-datetime {
  --background: #fff;
  --background-rgb: 255, 255, 255;
  border-radius: 16px;
}

/* Safe area padding */
ion-toolbar {
  --padding-top: env(safe-area-inset-top);
  /* --padding-bottom: env(safe-area-inset-bottom); */
  --padding-left: env(safe-area-inset-left);
  --padding-right: env(safe-area-inset-right);
}

.search-container {
  padding-top: env(safe-area-inset-top);
}

/* QR stuff */
body.scanner-active {
  --background: transparent !important;
  --background-color: transparent !important;
  --ion-background-color: transparent !important;
  opacity: 0 !important;
}

.scanner-active {
  --background: transparent !important;
  background-color: transparent !important;
  --ion-background-color: transparent !important;
  opacity: 0 !important;
}

.ion-padding-toolbar {
  padding: 8px;
}

/* Stripe custom styles */
/* spinner/processing state, errors */
.stripe-form .spinner,
.stripe-form .spinner:before,
.stripe-form .spinner:after {
  border-radius: 50%;
}

.stripe-form .spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.stripe-form .spinner:before,
.stripe-form .spinner:after {
  position: absolute;
  content: "";
}

.stripe-form .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #181818;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.stripe-form .spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #181818;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
